//import useState hook to create menu collapse state
import React, { useState } from "react";

//import sidebar css from react-pro-sidebar module and our custom css 
import "react-pro-sidebar/dist/css/styles.css";
import "./SiteHeader.css";

import useAuthStore from "../../stores/authStore";
import { useNavigate } from 'react-router-dom';

const SiteHeader = () => {
    const user = useAuthStore(state => state.user);
    const logout = useAuthStore(state => state.logout);
    const navigate = useNavigate();


    return (
        <>

            <div class="siteheader_container">
                <div class="siteheader_row">

                    <div class="siteheader_box">
                        <div class="siteheader_logo">
                            <img src="logo.jpg"
                                alt="logo"
                                // border radius
                                style={{ borderRadius: 5 }}
                            />
                        </div>
                    </div>

                    <div class="siteheader_box group">
                        <div class="siteheader_clickable">
                            <a href="https://essaygenius.ai/topics"
                                target="_blank"
                                rel="noopener noreferrer"
                            >Pricing</a>
                        </div>
                        {user ? (
                            <div class="siteheader_clickable register"
                                onClick={(e) => {
                                    logout(e);
                                    navigate('/login')
                                }}
                            >
                                Logout
                            </div>
                        ) : (
                            <>
                                <div class="siteheader_clickable login"
                                >
                                    Login
                                </div>
                                <div class="siteheader_clickable register">
                                    Signup
                                </div>
                            </>

                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default SiteHeader;
import React from "react";
// AuthStack
import Login from "./screens/authStack/Login";
import Register from "./screens/authStack/Register";

// AppStack
import Account from "./screens/appStack/Account";

import { Route, Routes } from "react-router-dom";

import useAuthStore from "./stores/authStore";
import ProtectedRoute from "./navigation/ProtectedRoutes";
import UnprotectedRoute from "./navigation/UnprotectedRoutes";

import './App.css'
import SideMenu from "./components/allPages/SideMenu";
import SiteHeader from "./components/allPages/SiteHeader";
import HomePage from "./screens/appStack/HomePage/HomePage";

function App() {
  const user = useAuthStore(state => state.user);

  return (
    <div className="app">
      <SiteHeader />

      {/* {user ? <SideMenu /> : null} */}
      <Routes>
        {/* AUTHstack */}
        <Route path="/login" element={<UnprotectedRoute><Login /></UnprotectedRoute>} />
        <Route path="/register" element={<UnprotectedRoute><Register /></UnprotectedRoute>} />

        {/* APPstack */}
        <Route path="/" element={
          <ProtectedRoute><Account /></ProtectedRoute>} />
        <Route path="/home" element={
          <ProtectedRoute><HomePage /></ProtectedRoute>} />
      </Routes>
    </div>
  );
}

export default App;

const { Configuration, OpenAIApi } = require("openai");

const configuration = new Configuration({
    apiKey: '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',
});

const openai = new OpenAIApi(configuration);

export const getAnswer = async (message) => {
    const response = await openai.createCompletion({
        model: "text-davinci-003",
        prompt: `${message} 100 words only`,
        max_tokens: 1000,
        temperature: 0.5,
    });
    console.log('response', response.data.choices[0].text);
    return { message: response.data.choices[0].text }
};

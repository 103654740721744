// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyC0GkZX6I-hsIa06OGNUwgSOILQ5jG5juA",
    authDomain: "askit-8701e.firebaseapp.com",
    projectId: "askit-8701e",
    storageBucket: "askit-8701e.appspot.com",
    messagingSenderId: "532434965556",
    appId: "1:532434965556:web:b3fbd9856f52b21a5fa661",
    measurementId: "G-HZGVQJYEX1"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app




//import useState hook to create menu collapse state
import React, { useState } from "react";

//import sidebar css from react-pro-sidebar module and our custom css 
import "react-pro-sidebar/dist/css/styles.css";
import "./HomePage.css";

import useAuthStore from "../../../stores/authStore";
import { useNavigate } from 'react-router-dom';

// getAnswer
import { getAnswer } from "../../../utils/getAnswer";

const HomePage = () => {
    const user = useAuthStore(state => state.user);
    const logout = useAuthStore(state => state.logout);
    const navigate = useNavigate();

    const [showAnswer, setShowAnswer] = useState(false);
    const [answer, setAnswer] = useState("");

    const [input, setInput] = useState("");

    // Submit answer with input and then set showAnswer to true and then set answer to the answer
    const handleSubmit = async (e) => {
        e.preventDefault();
        const answer = await getAnswer(input);
        setShowAnswer(true);
        setAnswer(answer.message);
        console.log('answer: ', answer);
    };

    // const handleSubmit = async (e) => {
    return (
        <>

            <div
                className="HomePage_container"            >
                <div>
                    <h1 className='HomePage__heading'>
                        AskIt your essay question:
                    </h1>

                    <div>
                        <form className="text_input_container"
                        // onSubmit={handleSubmit}
                        >

                            <input className="text_input_area"
                                rows="3"
                                placeholder="Write me an essay on how powerful AI essays are."
                                // Placeholder color
                                // value={input}
                                onChange={e => setInput(e.target.value)}
                            >
                            </input>
                            <button
                                // Show answer to true and don't refresh page
                                onClick={(e) => {
                                    if (input.length === 0) {
                                        e.preventDefault();
                                        setShowAnswer(true)
                                        return;
                                    } else {
                                        // e.preventDefault();
                                        // setShowAnswer(true)

                                        handleSubmit(e);
                                    }
                                }}
                            >Try now</button>
                        </form>
                    </div>
                    {showAnswer && answer.length > 0 && <><div className="answer_container answer">
                        <p>
                            {answer}
                        </p>
                    </div>
                    </>}

                    {showAnswer && answer.length == 0 && <><div className="answer_container answer">
                        <p>
                            Artificial intelligence (AI) has the potential to be incredibly powerful, and its applications are vast and varied. From self-driving cars to language translation to personalized medical treatment, AI is making a big impact on many different industries.
                            <br></br><br></br>
                            One area where AI is particularly powerful is in the field of essay writing. With the ability to analyze vast amounts of data and use it to generate written content, AI-powered essay writing tools have the potential to revolutionize the way we approach education.{'\n'}
                            <br></br><br></br>
                            One of the main benefits of AI-powered essay writing tools is the speed and efficiency with which they can produce high-quality written work. These tools are able to analyze a prompt or topic, conduct research on the subject, and generate a well-written and well-researched essay in a matter of minutes. This is a significant advantage for students and professionals who may not have the time or resources to conduct their own research and write a detailed essay.
                            <br></br><br></br>
                            In addition to speed and efficiency, AI-powered essay writing tools can also help to improve the quality of written work. By analyzing data and using it to generate content, these tools are able to produce essays that are more accurate, well-researched, and well-written than those produced by humans. This can help to raise the overall standard of written work in a given field, as well as increasing the chances of success for those using these tools.
                            <br></br><br></br>
                            However, it's important to note that AI-powered essay writing tools are not a replacement for human writers. While they can produce high-quality work quickly and efficiently, they do not have the same level of creativity and critical thinking skills as humans. Therefore, it is important to use these tools as a supplement to, rather than a replacement for, traditional writing methods.
                            <br></br><br></br>
                            Overall, AI-powered essay writing tools are a powerful tool that can greatly benefit students, professionals, and educators. By providing a fast and efficient way to produce high-quality written work, these tools have the potential to revolutionize the way we approach education and written communication.
                        </p>
                    </div>
                    </>}

                </div>

                <div

                // Box for description
                >
                    <h2 className='HomePage__subheading'>
                        AskIt anything,  <br></br>get the model answer.
                    </h2>
                    <h2 className='HomePage__mainParagraph'>
                        AskIt uses cutting-edge AI to help you write your essays like never before. <br></br>Complete & rephrase sentences, generate new paragraphs, and have your essay structure built for you.
                    </h2>
                </div>

            </div>


        </>


    );
};

export default HomePage;
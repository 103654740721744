import create from "zustand";

import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
} from 'firebase/auth';
import { auth } from '../firebase';



const useAuthStore = create((set) => ({
    user: {},
    setUser: user => set(state => ({ ...state, user })),
    createUser: async (e, email, password) => { try { e.preventDefault(); await createUserWithEmailAndPassword(auth, email, password); return true } catch (e) { console.log(e.message); alert(e.message); return false } },
    signIn: async (e, email, password) => { try { e.preventDefault(); await signInWithEmailAndPassword(auth, email, password); return true } catch (e) { console.log(e.message); alert(e.message); return false } },
    logout: (e) => { try { signOut(auth) } catch (e) { console.log(e.message); alert(e.message) } },
}));

export default useAuthStore;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../../stores/authStore';
import '../../css/Account.css';

const Account = () => {

  const user = useAuthStore(state => state.user);
  const logout = useAuthStore(state => state.logout);
  const navigate = useNavigate();

  return (
    <div className='account__container'>
      <h1 className='account__heading'>
        Account</h1>

      <p>User email: {user && user.email}</p>

    </div >
  );
};

export default Account;
